<template>
  <div>
    <vx-card :is-refresh="isLoading">

      <div class="flex items-center justify-between space-x-3">
        <h4 class="">Property Sold</h4>
        <div class="flex items-center space-x-2">
          <ProyekList class="w-48" @proyekRefreshed="params.id_proyek = $event; getData();" />
          <vs-select class="w-32 vs-select-small" v-model="params.period" @input="onChangePeriod($event)">
            <vs-select-item class="vs-select-small" v-for="(it, index) in ['THIS YEAR', 'LAST YEAR']" :key="index" :value="it" :text="it"/>
          </vs-select>
        </div>
      </div>

      <div class="p-6 pb-0">
        <div class="sm:flex">
          <div class="mr-6" v-for="legend in chartData.legends" :key="legend.key">
            <p class="mb-1 font-semibold">{{ legend.alias }}</p>
            <p class="text-3xl" :class="{'text-success': legend.value > 0}"><sup class="text-base mr-1">Rp</sup>{{ legend.value | idr(0) }}</p>
          </div>
        </div>
        <vue-apex-charts
          type="line"
          height="240"
          :options="chartOptions"
          :series="chartData.series" />
      </div>
    </vx-card>
  </div>
</template>

<script>
import ReportRepository from '@/repositories/reports/report-repository'
import VueApexCharts from 'vue-apexcharts'
import ProyekList from '@/views/pages/dashboard/ProyekList'
import { abbreviateNumber } from '@/utilities/common/global-methods'
import moment from 'moment/moment'

export default {
  name: 'PropertySaleLineChart',
  components: {
    VueApexCharts,
    ProyekList
  },
  mounted () {
    this.initData()
  },
  data () {
    return {
      isLoading: false,
      params: {
        id_proyek: null,
        period: 'THIS YEAR',
        start_date: moment().startOf('year').format('YYYY-MM-DD'),
        end_date: moment().endOf('year').format('YYYY-MM-DD')
      },
      data: {
        queryResult: []
      },
      chartData: {
        legends: [],
        categories: [],
        series: []
      },
      chartOptions: {
        chart: {
          toolbar: { show: false },
          dropShadow: {
            enabled: true,
            top: 5,
            left: 0,
            blur: 4,
            opacity: 0.10
          }
        },
        stroke: {
          curve: 'smooth',
          dashArray: [0, 0, 8],
          width: [2, 2, 2]
        },
        grid: {
          borderColor: '#e7e7e7'
        },
        legend: {
          show: true
        },
        colors: ['#0081ff', '#e71f39', '#14c51d'],
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            inverseColors: false,
            gradientToColors: ['rgb(0,129,255)', 'rgb(231,31,57)', 'rgb(20,197,29)'],
            shadeIntensity: 1,
            type: 'horizontal',
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100, 100, 100]
          }
        },
        markers: {
          size: 0,
          hover: {
            size: 5
          }
        },
        xaxis: {
          labels: {
            style: {
              cssClass: 'text-grey fill-current'
            }
          },
          axisTicks: {
            show: false
          },
          categories: [],
          axisBorder: {
            show: false
          }
        },
        yaxis: {
          tickAmount: 5,
          labels: {
            style: {
              cssClass: 'text-grey fill-current'
            },
            formatter (val) {
              return val > 999 ? abbreviateNumber(val.toFixed()) : val
            }
          }
        },
        tooltip: {
          x: { show: false }
        }
      }
    }
  },
  methods: {
    initData () {
      this.getData()
    },

    onChangePeriod (period) {
      if (period === 'THIS YEAR') {
        this.params.start_date = moment().startOf('year').format('YYYY-MM-DD')
        this.params.end_date = moment().endOf('year').format('YYYY-MM-DD')
      } else if (period === 'LAST YEAR') {
        this.params.start_date = moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD')
        this.params.end_date = moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD')
      }

      this.getData()
    },

    getData () {
      this.isLoading = true

      const reportId = 104
      const params = { params: this.params }
      return ReportRepository.templateData(reportId, params)
        .then(response => {
          // this.data = response.data
          this.chartData = response.data.chartData
          this.chartOptions.xaxis.categories = response.data.chartData.categories
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>
